@webcall-width : 232px;

:root {
    --brand-primary: @brand-primary;

    --portal-border: var(--brand-primary);
    --main-border: #aeb0b5;

    --tb-tabs-bg: var(--brand-primary);
    --tb-tabs-fg: #fff;
    --tb-tabs-border: @color-primary;
    --tb-tabs-highlight-active: var(--tb-tabs-bg);
    --tb-tabs-highlight-hover: #fff;
    --tb-tabs-active-bg: #fff;
    --tb-tabs-active-fg: var(--tb-tabs-bg);
    --tb-tabs-hover-bg: @color-primary-darkest;
    --tb-tabs-hover-fg: #fff;
    --tb-tabs-focus-outline: @color-btn-primary-bg;

    --tb-tabs-notice-bg: var(--main-border);
    --tb-tabs-notice-fg: #000;
    --tb-tabs-notice-urgent-bg: @state-danger-bg;
    --tb-tabs-notice-urgent-fg: #fff;
}

@import "fonts/tbfont";
@import "fonts/lato";

@import "bootstrap/variables";
@import "bootstrap/bootstrap";

@panel-footer-bg: #fff;

@import "type";
@import "forms";
@import "dropdowns";
@import "print";

@import "components/alerts";
@import "components/datepicker";
@import "components/modals";
@import "components/button-groups";
@import "components/button-toolbars";
@import "components/overlay";
@import "components/loading-indicator";
@import "components/dropzone";
@import "components/media-element-player";
@import "components/subpage";
@import "components/panels";
@import "components/popup";
@import "components/progress";
@import "components/range-input";
@import "components/range-input-popup";
@import "components/tables";
@import "components/tabs";
@import "components/toggle";
@import "components/loader-error";

@import "utility";

@import "button-mapper";

@import "portal";
@import "lcm-panel";
@import "settings";
@import "report";
@import "wallet";
@import "chat";
@import "managed-queue-control";
@import "audio-recorder";

.tbicon-large {
    font-size: 20px;
    font-family: tbfont;
    color: @brand-primary;
}

.icon-label {
    display: flex;
    align-items: center;
    padding: 6px;

    .tbicon-large {
        margin-right: auto;
    }

    .label {
        margin-left: 2px;
    }
}

.btn-link-large {
    font-size: 18px;
}

.pnlWebCallApp {
    @import "../WebCall/less/webCallApp";
}

.webcall-conf-app-container {
    position: relative;
    flex: 0 0 @webcall-width;
    margin-left: auto;

    .btn-startConnect {
        position: absolute;
        top: 8px;
        right: 0;
    }
}

.recording-main-container {
    .label {
        font-size: 16px;
        font-weight: bold;
    }

    table {
        width: 100%;

        td {
            padding-bottom: 5px;
            vertical-align: bottom;

            &:not(:first-child) {
                padding-left: 2px;
            }

            &:not(:last-child) {
                padding-right: 2px;
            }
        }
    }

    .downloadLink {
        font-size: 16px;
    }
}

@import "components/loader";
