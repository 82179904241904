.overlay-parent {
    position: relative;

    .overlay {
        display: none;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    &.overlay-active {
        &.overlay-hide-content {
            > * {
                visibility: hidden;
            }
        }

        .overlay {
            display: flex;
            visibility: visible;
        }
    }
}
