progress {
    appearance: none;
    background: transparent;
    border-radius: @progress-border-radius;
    border: none;

    height: @progress-slider-height;

    color: @progress-bg;

    &::-webkit-progress-bar {
        background: transparent;
    }

    &::-webkit-progress-value {
        background: currentColor;
        border-radius: @progress-border-radius;
    }

    &::-moz-progress-bar {
        background: currentColor;
        border-radius: @progress-border-radius;
    }

    &::-ms-fill {
        border-radius: @progress-border-radius;
    }

    &.progress-primary {
        background: @progress-bg;
        color: @progress-bar-color;
    }
}
