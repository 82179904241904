.alert {
    position : relative;
    padding : 10px;
    font-size : 16px;

    &.alert-icon {
        font-weight : bold;

        padding-left : 34px;

        &:before {
            position : absolute;
            top : 8px;
            left : 8px;

            display : block;
            font-size : 20px;
            .tbicon();
        }

        &.alert-danger:before {
            .kvp(@tbfontglyphmap, alert-circle);
            content : @kvp;
        }

        &.alert-success:before {
            .kvp(@tbfontglyphmap, info-circle);
            content : @kvp;
            color : @brand-primary;
        }
    }
}
