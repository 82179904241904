.dropzone-target-overlay {
    z-index: 100;

    .dropzone-target-overlay-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        margin: 0.25rem;
        background-color: #fff;
        opacity: 0.8;
        border: 4px dashed grey;
        color: grey;
        font-size: 5rem;
    }
}
