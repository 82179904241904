.managed-queue-control {

  .managed-queue-table-controls {
      margin: 6px 0;
  }

  .expand-indicator {
      display: flex;
      justify-content: center;

      .expand-indicator-inner {
          cursor: pointer;
          background-color: #fff;
          border: 1px solid var(--main-border);
          border-radius: 4px;
          transform: translateY(-10px);
          margin-bottom: -10px;
          padding: 2px;
      }
  }
}

.drag-list() {
    user-select: none;

    &.draggable {
        cursor: grab;
    }

    li[data-drag-src] {
        .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-btn-primary-focus-hover-border}");
    }

    li[data-drag-dest=before] {
        border-top: 2px dashed #000 !important;
    }

    li[data-drag-dest=after] {
        border-bottom: 2px dashed #000 !important;
    }
}


.managed-queue-list {
    counter-reset: managed-queue-list-counter;
    .drag-list();
    @item-min-height: 28px;

    list-style-type: none;

    padding: 0;
    margin: 0;

    > li {
        display: flex;
        align-items: center;
        min-height: @item-min-height;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: var(--main-border);

        counter-increment: managed-queue-list-counter;
    }

    li {
        border-top-width: 1px;
    }

    .hover-cell {
        display: flex;
        width: 48px;
        // override overflow so that hover state outline is
        // visible. changing to an internal box-shadow for
        // hover-cell hover style would obviate the need for this
        overflow: visible;
        padding: 0;

        button {
            width: 100%;
            height: @item-min-height;
            border: 0;
            background-color: transparent;
            box-shadow: none;
        }

        button:hover {
            outline: @color-primary solid 2px;
        }
    }

    li.draggable::before {
        padding: 0;
        width: 18px;
        height: @item-min-height;
        background-position: left 2px center;
        background-repeat: no-repeat;
        background-size: auto 22px;
        background-origin: padding-box;
        background-image: data-uri('image/svg+xml;base64', 'icons/drag-vertical.svg');
        content: "";
    }
}

.btn-flat {
    // general button rules
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    white-space: nowrap;
    appearance: none;
    .user-select(none);

    // btn-flat specifics
    height: 24px;
    width: 60px;
    background-color: transparent;
    font-family: tbfont;
    // override user-agent focus outline
    outline: 0;

    border: 2px solid transparent;
    border-radius: 4px;

    &:hover,
    &:focus,
    &.focus {
        border-color: @color-primary;
    }
}
