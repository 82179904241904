.btn-toggle {
    .tbicon();

    &:not(.btn) {
        cursor              : pointer;

        color : lighten(black, 35%);

        &:hover {
            color : black;
        }

        &:disabled {
            cursor : @cursor-disabled;
            color : lighten(black, 70%);
        }
    }

    .set-glyph(checkbox-unchecked);

    &[data-checked] {
        &:before {
            color: @brand-primary;
            .set-glyph-content(solid-checkbox-checked);
        }
    }

    &[data-indeterminate] {
        &:before {
            color : @brand-primary;
            .set-glyph-content(solid-checkbox-indeterminate);
        }
    }

    &[data-icon-type=star] {
        font-size : 16px;
        .set-glyph(star-unchecked);

        &[data-checked] {
            &:before {
                color : @brand-primary;
                .set-glyph-content(star-checked);
            }
        }
    }
}
