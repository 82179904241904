.print-header-overlay {
    .print-header-overlay-inner {
        width: @lcm-width;
        margin: 10px auto;
        padding: 0 17px;
    }
}

.subpage.subpage-report {
    .form-inline {
        margin-bottom : 5px;

        .control-label {
            margin-right: 4px;
        }
    }

    .subpage-table {
        .btn-recording {
            .btn-recording-inner {
                display: flex;
                justify-content: center;
            }

            .playback-options {
                font-size: 10px;
            }

            &[data-is-default] {
                background-color: @brand-primary;
                color: #fff;
            }
        }

        .comment {
            max-width : 970px;
            overflow : hidden;
        }
    }
}

.subpage.subpage-recordings {
    .subpage-table {
        .set-table-row-height(34px);

        .column-customID {
            max-width: 370px;

            button {
                overflow: hidden;
                text-overflow: ellipsis;

                text-align: left;
            }
        }
    }
}

.subpage-confDetails {
    .column-callerID {
        max-width : 370px;
        overflow : hidden;
        text-overflow : ellipsis;
    }

    .caller-name {
        max-width : 370px;
        overflow : hidden;
        text-overflow : ellipsis;
    }
}

.fg-datepicker {
    input {
        width: 100px !important;
    }
}
