.tb-tabs-container {
    display: flex;

    background-color: var(--tb-tabs-bg);

    border: solid var(--tb-tabs-border);
    border-width: 1px 0 1px;

    > * {
        margin-top: -1px !important;
    }

    > :last-child {
        border-right-width: 1px;
    }
}

.tb-tabs {
    flex-grow: 1;

    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: 0;
    white-space: nowrap;

    > li {
        display: block;
        list-style: none;
    }

    > li > a {
        .tb-tabs-tab();
    }

    > li.tb-tabs-active a {
        font-weight: bold;
        background-color: var(--tb-tabs-active-bg);
        color: var(--tb-tabs-active-fg);

        .tb-tabs-add-highlight(var(--tb-tabs-highlight-active));
    }

    &::after {
        content: '\a0';
        flex-grow: 1;
        padding: 4px 8px;

        border: solid var(--tb-tabs-border);
        border-width: 1px 0 0 1px;
    }

    .tb-tabs-notice {
        display: inline-block;
        text-align: center;
        padding: 0 4px;
        border-radius: 2px;
        min-width: 20px;

        font-weight: bold;
        background-color: var(--tb-tabs-notice-bg);
        color: var(--tb-tabs-notice-fg);

        &.tb-tabs-notice-urgent {
            background-color: var(--tb-tabs-notice-urgent-bg);
            color: var(--tb-tabs-notice-urgent-fg);
        }
    }
}

.tb-tabs-tab {
    display: block;
    padding: 4px 8px;

    text-decoration: none;
    outline: none;

    border: solid var(--tb-tabs-border);
    border-width: 1px 0 0 1px;

    background-color: transparent;
    color: var(--tb-tabs-fg);

    &:hover {
        background-color: var(--tb-tabs-hover-bg);
        color: var(--tb-tabs-hover-fg);

        .tb-tabs-add-highlight(var(--tb-tabs-highlight-hover));
    }

    &:focus {
        outline: var(--tb-tabs-focus-outline) 2px solid;
    }
}

.tb-tabs-add-highlight(@bg-color) {
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        left: 8px;
        right: 8px;
        bottom: 0;
        background-color: @bg-color;
    }
}
