// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

.tb-button-variant(@color; @background; @border; @focus-hover-border; @active-color; @active-background) {
  transition: box-shadow .12s linear, border .12s linear, color .12s linear, background-color .12s linear, transform .12s linear;
  color: @color;
  background-color: @background;
  border-color: @border;

  &:focus,
  &.focus {
    color: @color;
    background-color: darken(@background, 10%);
        border-color: @focus-hover-border;

    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{focus-hover-border}");
    outline : none;
  }
  &:hover {
    color: @color;
    border-color: @focus-hover-border;
    transform : translate(-1px, -1px);
    .box-shadow(~"inset 1px 1px 1px 1px rgba(0,0,0,.075), 1px 1px 8px @{focus-hover-border}");
    outline : none;

    &:focus,
    &.focus {
        background-color: darken(@background, 10%);
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @active-color;
    background-color: @active-background;

    .box-shadow(none);

    &:hover,
    &:focus,
    &.focus {
      transform : none;
      color: @active-color;
      background-color: @active-background;
    }
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus,
    &:active {
      background-color: @background;
      border-color: @border;
      color : @color;
    }
  }

  .badge {
    color: @background;
    background-color: @color;
  }
}

.tb-button-variant-solid(@bg, @fg) {
    @btn-fg: @fg;
    @btn-bg: @bg;
    @btn-border: @bg;
    @btn-focus-hover-border: @bg;
    @btn-active-fg: @fg;
    @btn-active-bg: darken(@bg, 20%);

    .tb-button-variant(@btn-fg; @btn-bg; @btn-border; @btn-focus-hover-border; @btn-active-fg; @btn-active-bg);
}

// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);

  .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      //.tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none) !important;
    transform : none !important;
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
