.btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    > .btn,
    > input {
        position: relative;

        // hack to prevent Safari from displaying gaps between elements and ignoring flex property
        // no longer needed and conflicts with .dropdown-toggle style
        // display: block;

        // Bring the hover, focused, and "active" buttons to the front to overlay
        // the borders properly
        &:hover,
        &:focus,
        &:active,
        &.active {
            z-index: 2;
        }
    }

    // Prevent double borders when buttons are next to each other
    > .btn:not(:first-child),
    > .btn-group:not(:first-child),
    > input:not(:first-child),
    > div:not(:first-child) {
        margin-left: -1px;
    }


    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn,
    > input:not(:last-child),
    > div:not(:last-child) {
        .border-right-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn,
    > input:not(:first-child),
    > div:not(:first-child) {
        .border-left-radius(0);
    }
}

.btn-group-justified {
    > .btn,
    > .btn-group {
        flex: 1;
        // hack to prevent Safari from displaying gaps between elements and ignoring flex property
        // no longer needed and conflicts with .dropdown-toggle style
        // display: block;
    }
}

.btn-group-no-collapse {
    > .btn:not(:first-child),
    > .btn-group:not(:first-child),
    > input:not(:first-child),
    > div:not(:first-child) {
        margin-left: 0 !important;
    }
}
