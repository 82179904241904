.popup {
	z-index: 1060;

	display: none;
	&[data-popup-show] {
		display: block;
	}
}

.popup-bg {
	background-color: #fff;
	border-radius: @border-radius-base;
	border: 1px solid @dropdown-border;
	.box-shadow(0 6px 12px rgba(0,0,0,.175));
	background-clip: padding-box;
}
