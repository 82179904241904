.media-element-player {
    @progress-buffering-bg-size: 3rem;

    .button-mapper(~".btn-toggle-playback"        , hold);
    .button-mapper(~".btn-toggle-playback.paused" , play);

    .scrubber-control {
        position: relative;
        flex-grow: 1;

        input[type=range], progress {
            width: 100%;
        }

        input[type=range] {
            position: relative;
            z-index: 2;

            &::-webkit-slider-runnable-track {
                background-color: fade(@progress-bg, 40%);
            }

            &::-moz-range-track {
                background-color: fade(@progress-bg, 40%);
            }

            &::-ms-track {
                background-color: fade(@progress-bg, 40%);
            }

            &::-ms-fill-upper {
                background-color: fade(@progress-bg, 40%);
            }
        }

        progress {
            position: absolute;
            height: @range-input-track-height;
            top: 7px;
            left: 0;

            &.buffering {
                animation: scrubber-control-buffering 1s linear infinite;
                background-image: repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 1rem,
                    @progress-bg 1rem,
                    @progress-bg 2rem
                );
                background-size: @progress-buffering-bg-size;
                color: transparent;
            }
        }
    }

    .volume-icon {
        .button-mapper(~"&", volume-up);
        font-size: 2rem;
    }

    .volume {
        flex-basis: 15%;
    }

    .btn-toggle-playback {
        padding: .5rem 1.5rem;
    }

    @keyframes scrubber-control-buffering {
        to {
            background-position: @progress-buffering-bg-size 0;
        }
    }

    // used for split LAYOUT_TYPE
    .bottom {
        margin-top: .5rem;

        .volume-icon {
            margin-left: auto;
        }
    }
}

.media-element-player-control-bar {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
        margin-left: .5rem;
    }
}
