@import "./tb-button-variant.less";

// Alternate buttons
// --------------------------------------------------

.btn-primary {
    .tb-button-variant(@color-btn-primary-fg; @color-btn-primary-bg; @color-btn-primary-border; @color-btn-primary-focus-hover-border; @color-btn-primary-active-fg; @color-btn-primary-active-bg);
}
.btn-primary-toggled {
    .tb-button-variant(@color-btn-primary-toggled-fg; @color-btn-primary-toggled-bg; @color-btn-primary-toggled-border; @color-btn-primary-toggled-focus-hover-border; @color-btn-primary-toggled-active-fg; @color-btn-primary-toggled-active-bg);
}
.btn-gray {
    .tb-button-variant(@color-btn-gray-fg; @color-btn-gray-bg; @color-btn-gray-border; @color-btn-gray-focus-hover-border; @color-btn-gray-active-fg; @color-btn-gray-active-bg);
}

.btn-success {
    .tb-button-variant(@color-btn-success-fg; @color-btn-success-bg; @color-btn-success-border; @color-btn-success-focus-hover-border; @color-btn-success-active-fg; @color-btn-success-active-bg);
}

.btn-warning {
    .tb-button-variant(@color-btn-warning-fg; @color-btn-warning-bg; @color-btn-warning-border; @color-btn-warning-focus-hover-border; @color-btn-warning-active-fg; @color-btn-warning-active-bg);
}

.btn-danger {
    .tb-button-variant(@color-btn-danger-fg; @color-btn-danger-bg; @color-btn-danger-border; @color-btn-danger-focus-hover-border; @color-btn-danger-active-fg; @color-btn-danger-active-bg);
}
