.audio-recorder {
  display: flex;

  .btn-record {
    color: @state-danger-bg;
  }

  .volume-visualizer-outer {
    border-style: solid;
    border-width: 1px;
    width: 100%;

    .volume-visualizer-inner {
      height: 100%;
      max-width: 100%;
      background-color: blue;
    }
  }
}

.audio-recorder-duration {
  padding-top: 6px;
}
