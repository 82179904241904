.thumb-common() {
    appearance: none;
    height: @range-input-thumb-height;
    width: @range-input-thumb-width;
	background-color: @range-input-thumb-color;
    border-radius: @range-input-thumb-radius;
    border: none;
}

.track-common() {
    height: @range-input-track-height;
    background: @progress-bg;
    border-radius: @progress-border-radius;
    border: none;
}

input[type=range] {
    appearance: none;
    height: @range-input-track-height;

    margin: 0;
    padding: @range-input-track-padding 0;

    cursor : pointer;

    background: transparent;
    color: @progress-bar-bg;
    outline: none;

    user-select: none;
    touch-action: manipulation;

    &::-moz-focus-outer {
        border: 0;
    }

    // Thumb

    &::-webkit-slider-thumb {
        .thumb-common();
        margin-top: ((@range-input-track-height - @range-input-thumb-height) * 0.5);
    }

    &::-moz-range-thumb {
        .thumb-common();
    }

    // Track

    &::-webkit-slider-runnable-track {
        .track-common();
        background-image: linear-gradient(
            to right,
            currentColor var(--value, 0%),
            transparent var(--value, 0%)
        );
    }

    &::-moz-range-track {
        .track-common();
    }

    &::-moz-range-progress {
        .track-common();
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background: currentColor;
    }
}
