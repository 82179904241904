.btn-toolbar {
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 3px;
    }
}

.btn-toolbar-discrete {
    .btn-toolbar();

    > .btn {
        min-width: 60px;
    }
}

.btn-toolbar-full {
    .btn-toolbar();

    > .btn {
        flex: 1 0 0;
    }
}
