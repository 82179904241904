.range-input-popup {
  padding-left: 30px;
  padding-right: 30px;

  .popup-bg {
    width: 122px;
    height: 22px;
    transform-origin: 0 0;

    input {
      padding: 10px;
    }
  }
}
