.print-only {
    display: none;
    body.body-print & {
        display: block;
    }
}

.print-hide {
    body.body-print & {
        display: none;
    }

    @media print {
        display: none !important;
    }
}
