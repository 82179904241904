@import "functions/kvp";

.button-mapper(@name, @glyphname) {
    @{name} {
        font-family : tbfont;

        &:before {
            .kvp(@tbfontglyphmap, @glyphname);
            content : @kvp;
        }
    }
}
