//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

.modal-common-mixin() {
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;
}

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  .modal-common-mixin();
  overflow: hidden;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  &[aria-hidden=false] {
      display: block;
  }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
}

// Actual modal
.modal .modal-content {
  position: relative;
  background-color: @modal-content-bg;
  border: 1px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid @modal-content-border-color;
  border-radius: @border-radius-large;
  .box-shadow(0 3px 9px rgba(0,0,0,.5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal-background;
  background-color: @modal-backdrop-bg;
  opacity: 0.5;
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: center;

  padding: @modal-title-padding;
  border-bottom: 1px solid @modal-header-border-color;

  background-color: @brand-primary;
  color: #fff;

  .modal-draggable & {
    cursor: move;
  }
}

.modal-close {
    margin-left: auto;

    font-size: (@font-size-base * 1.5);
    font-weight: bold;
    text-shadow: 0 1px 0 #fff;

    // <button> overrides
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;

    color: #fff;
    &:hover {
        color: darken(#fff, 33%);
    }
}

// Title text within header
.modal-title {
  font-size: 18px;
  font-weight: 700;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-content-panel .modal-body {
  padding: @modal-inner-padding;
}

// Footer (for actions)
.modal .modal-footer {
  padding: @modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 1px solid @modal-footer-border-color;

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

// Scale up the modal
@media (min-width: @screen-sm-min) {
  .modal {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
      width: @modal-md;
    }
    .modal-content {
      .box-shadow(0 5px 15px rgba(0,0,0,.5));
    }

    // Modal sizes
    .modal-sm { width: @modal-sm; }
  }
}

.modal {
    .modal-content {
        overflow : hidden;

        .modal-footer {
            .btn {
                min-width : 60px;
            }
        }
    }
}

.modal-dialog.modal-icon {
    .modal-body {
        display : table;

        .icon {
            display : table-cell;
            vertical-align : middle;
            padding-right : 10px;
            font-size : 40px;

            .tbicon();

            color : @panel-primary-border;
        }

        .content {
            display : table-cell;
            vertical-align : middle;

            font-size : 16px;
            font-weight : bold;
        }
    }

    &.modal-dialog-danger {
        .icon {
            color: @state-danger-bg;
        }

        .content {
            color: @state-danger-bg;
        }
    }
}

.modal-action-list {
    .note {
        padding-top : 4px;
    }

    .row {
        margin-bottom : 10px;
    }

    .btn {
        width : 100%;
    }
}

.modal-dialog.modal-dialog-danger {
    .modal-header {
        background-color: @state-danger-bg;
    }
}

.modal-anchored {
  position: absolute;
  .modal-common-mixin();

  &[aria-hidden=false] {
      display: block;
  }
}

.modal-anchored .modal-dialog {
    height: 100%;
    background-color: @modal-content-bg;
}

.modal-anchored .modal-footer {
    padding-top: 8px;
}

.modal-anchored .modal-content-panel {
    background-color: @panel-bg;
    border: 1px solid transparent;
    border-color: @panel-primary-border;
    border-radius: @panel-border-radius;

    .modal-header {
        padding: @modal-title-padding @modal-inner-padding;
    }

    .modal-title {
        font-size: 16px;
    }

    .modal-footer {
        padding: 0 @modal-inner-padding @modal-inner-padding;
    }
}

.modal-anchored .modal-dialog.modal-dialog-danger .modal-content-panel {
    border-color: @state-danger-bg;
}
