.hand-raised-icon() {
    .set-glyph(hand);

    &:before {
        .hover-cell-button-icon();
    }

    &.selected {
        .set-glyph(hand-selected);

        .order {
            visibility: hidden;
        }
    }
}

.hover-cell-button-icon() {
    font-size: 20px;
    color: @brand-primary;
    font-family: tbfont;
}

.lcm-green-panel() {
    padding: 10px;
    margin-bottom: 5px;
    background-color: @alert-success-bg;
    border: 1px solid @alert-success-border;
    border-radius: @alert-border-radius;
}

body.drag-active {
    cursor: grabbing !important;
    user-select: none !important;
    > * {
        pointer-events: none;
    }
}

.drag-table() {
    &.draggable {
        cursor: grab;
    }

    tr[data-drag-src] {
        // scale hack from https://stackoverflow.com/questions/10874985/box-shadow-on-table-row-not-appearing-on-certain-browsers
        transform: scale(1);
        .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-btn-primary-focus-hover-border}");
        border-radius: 8px;
    }

    tr[data-drag-dest=before] td:not(:first-child,:last-child) {
        border-top: 2px dashed #000 !important;
    }

    tr[data-drag-dest=after] td:not(:first-child,:last-child) {
        border-bottom: 2px dashed #000 !important;
    }
}

.subpage.subpage-lcm {
    &:not(.state-in-sub-conf) {
        .in-sub-conf {
            display: none;
        }
    }

    &.state-in-sub-conf {
        .in-main-conf {
            display: none;
        }
    }

    &:not(.state-conf-active) {
        .conf-status {
            .opacity(.65);
        }
    }

    &.forbid-make-call {
        .requires-make-call {
            display: none;
        }
    }

    .lcm-toolbar {
        margin-bottom: 5px;
    }

    .conf-settings {
        width: 100%;

        .dropdown-select {
            .btn {
                width: 100%;
                text-align : left;

                .setting-label {
                    padding-right: 3px;
                    font-weight : bold;
                    color : @brand-primary;
                }
            }

            &.open, button:active:not([disabled]) {
                .setting-label {
                    color : white;
                }
            }
        }
    }

    .lcm-action-panel {
        margin-bottom: 5px;

        .btn {
            position : absolute;
            right : 8px;
            top : 7px;
        }
    }

    .hand-raising-control {
        .lcm-green-panel();

        table.hand-raising-table {
            .drag-table();

            td {
                background-color: #fff;
            }

            font-size: 14px;

            border-collapse: separate;
            table-layout: fixed;

            td, .hover-cell button {
                padding: 10px;
            }

            td {
                border-style: solid;
                border-color: var(--main-border);
                overflow: hidden;
                text-overflow: ellipsis;
            }

            tbody {
                user-select: none;

                tr:first-child td {
                    border-top-width: 1px;
                }

                td {
                    border-width: 0 0 1px 0;
                }

                td:first-child {
                    border-left-width: 1px;
                    border-radius: 8px 0 0 8px;
                }

                td:last-child {
                    border-right-width: 1px;
                    border-radius: 0 8px 8px 0;
                }
            }

            // disable .btn-toggle active state during drag
            body.drag-active & .btn-toggle {
                outline: none !important;
            }

            .drag-handle {
                padding: 0;
                width: 12px;
            }

            .drag-handle-bg {
                background-position: left 2px center;
                background-repeat: no-repeat;
                background-size: auto 22px;
                background-origin: padding-box;
                background-image: data-uri('image/svg+xml;base64', 'icons/drag-vertical.svg');
            }

            .hover-cell {
                width: 36px;
                // override overflow so that hover state outline is
                // visible. changing to an internal box-shadow for
                // hover-cell hover style would obviate the need for this
                overflow: visible;
            }

            .handRaisedIndexDisplay {
                width: 50px;
            }

            .actions {
                overflow: visible;
                padding: 0;
                width: 320px;

                .btn-toolbar-discrete {
                    justify-content: flex-end;
                    button {
                        width: 100px;
                    }
                }
            }

            td:last-child {
                padding: 0;
                width: 10px;
            }
        }

        .hand-raising-table-controls {
            margin: 6px 0;
        }

        .expand-indicator {
            display: flex;
            justify-content: center;

            .expand-indicator-inner {
                background-color: #fff;
                border: 1px solid var(--main-border);
                border-radius: 4px;
                transform: translateY(-10px);
                margin-bottom: -10px;
                padding: 2px;
            }
        }

        &.expandable {
            .expand-indicator-inner {
                cursor: pointer;
            }
        }

        &:not(.expandable) {
            .expand-indicator-inner {
                visibility: hidden;
            }
        }

        &:not(.expanded) {
            .hand-raising-table {
                tbody tr:not(:first-child) {
                    display: none;
                }
            }
        }

        &:not(.select-allowed) {
            [data-action='handSelect'] {
                display: none;
            }
        }
    }

    .broadcast-panel {
        margin-bottom: 4px;

        .position-duration {
            margin-left: 8px;
            margin-right: 8px;
        }

        .broadcast-queue-item {
            .broadcast-queue-item-count , .eq-spectrum {
                width: 32px;
                margin-right: 8px;
                text-align: right;
            }

            .broadcast-queue-item-count::before {
                content: counter(managed-queue-list-counter);
            }

            .eq-spectrum {
                .eq-bar {
                    fill: @brand-primary;
                }
            }
        }

        .broadcast-active {
            background-color: @alert-success-bg;

            .playing-label {
                width: 58px;
                padding-left: 2px;
                font-weight: bold;
            }
        }

        .broadcast-queue .broadcast-queue-item {
            border-top: 0;
        }
    }

    .conf-controls {
        white-space: nowrap;
        display: flex;
        // override normal 5px bottom margin to account for .divCalls padding-top
        margin-bottom: 3px;

        > *:not(:last-child) {
            margin-right: 3px;
        }

        > * {
            // workaround for https://github.com/philipwalton/flexbugs#flexbug-1
            // Browsers affected
            // Chrome (fixed in 72)
            // Opera (fixed in 60)
            // Safari (fixed in 10)
            //
            // also fixes the issue for IE11

            flex-shrink: 0;
        }

        .conf-status {
            .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
            display: flex;
            width: 464px;

            > * {
                flex: 1 1 0px; // divide evenly
            }

            border: 1px solid var(--main-border);
            font-weight: bold;

            .status-label {
                margin-right: 3px;
                color: @brand-primary;
            }

            .tbicon {
                display: inline-block;
                vertical-align: top;
            }

            .status-label.tbicon {
                margin-left: 8px;
            }
        }

        .conf-status.ro-only {
            min-width: 840px;
        }

        .btn-select-all {
            .dropdown-toggle {
                padding-left : 2px;
                padding-right : 2px;

                > span:after {
                    padding: 0;
                }
            }
        }

        .btn-mute-all {
            width: 70px;
        }

        .btn-unmute-all {
            width: 90px;
        }

        .conf-controls-filter {
            flex-grow: 1;
            flex-shrink: 1;

            // inside flex container, inputs with 'width: auto' need
            // to have min-width set to 0 or some reasonable value to
            // prevent overflow
            min-width: 0;

            padding-right: 22px;
            background-position: right 4px center;
            background-repeat: no-repeat;
            background-size: auto 1em;
            background-origin: padding-box;
            background-image: data-uri('image/svg+xml;base64', 'icons/search.svg');
        }
    }

    .table-position-wrapper {
        position: relative;
    }

    .divCalls {
        overflow-x: auto;

        .status-overlays {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;

            // padding covers <thead> so that .panels are vertically
            // centered in <tbody>
            padding-top: (@font-size-base + 12px);

            .panel {
                z-index       : 100;

                min-width: 33%;

                .message-text {
                    font-size : 16px;
                }

                &.connection-error {
                    .btn-retry {
                        display : block;
                        margin-top : 10px;
                    }
                }
            }
        }

        table.dataTable {
            .set-table-row-height(30px);

            &.dimmer {
                .opacity(.65);
            }

            th, td {
                &.callerID {
                    width: 180px;
                    max-width : 200px;
                }

                &.caller-name {
                    max-width: 190px;

                    > button, > .label-only {
                        display: flex;
                        align-items: center;

                        strong {
                            margin-right: 2px;
                        }

                        .tbicon-large {
                            margin-left: 4px;
                            color: @state-danger-text;
                        }
                    }
                }

                &.location {
                    max-width: 190px;
                    overflow: hidden;
                }

                &.actionSelect {
                    width: 30px;
                }
            }

            // call activity/status

            div.activity {
                font-size : 20px;
                margin : 0 auto;
                width  : 20px;
                color : @brand-primary;

                .tbicon-volume-half, .tbicon-volume-full {
                    color : @color-secondary;
                }
            }

            .btnHandRaised {
                .inner {
                    display : inline-block;
                    margin : 0 auto;

                    .hand-raised-icon();

                    .order {
                        position:  relative;
                        top : -4px;
                    }
                }
            }

            .hand-raised-label {
                .hand-raised-icon();

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .dropdown-menu {
                min-width : 190px;
            }
        }
    }

    // hack to allow space for <th> outline hover
    .divCalls {
        padding-top: 2px;
    }
}
