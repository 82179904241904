@lcm-width : 1024px;

@media (min-width: @screen-md-min) {
    // add margin the width of scrollbar to prevent layout jump
    // https://aykevl.nl/2014/09/fix-jumping-scrollbar
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
}

.portal-container {
    display: flex;
    min-height: 100vh;

    .portal-container-content {
        display: flex;
        width: 100%;

        > :first-child {
            margin-left: auto;
        }
        > :last-child {
            margin-right: auto;
        }

        .app-main {
            flex-grow: 1;
            border-left: 2px solid var(--portal-border);
            border-right: 2px solid var(--portal-border);
            min-width: @lcm-width;
            max-width: 1200px;

            @media print {
                border : 0 !important;
            }

            // workaround for alignment in print-header-overlay
            // view. app-chat is hidden in this view so margin-right
            // needs to be added to app-main
            body.body-print & {
                margin-right: auto;
            }
        }
    }

    .portal-container-panel {
        margin: 0 auto;

        .portal-container-panel-inner {
            margin: 4px;
        }

        @media (min-width: @screen-sm-min) {
            width: 500px;
        }
    }

    &.app-compact {
        .portal-container-content {
            width: auto;

            .app-header {
                display: none;
            }

            .app-main {
                border: 0;
            }

            .app-chat {
                border-left: 2px solid var(--portal-border);
                border-right: 0;
            }
        }
    }
}

.portal-loading {
    margin: 10px auto;
}

.app-header {
    .print-hide();

    display: flex;

    min-height : 85px;
    padding-left: 8px;
    padding-right: 15px;

    .logo-container {
        margin-right: 22px;
        flex-shrink: 0;
        align-self: center;
    }

    .bridge-info-table {
        align-self: center;
    }
}

.tb-tabs-container {
    .print-hide();

    margin-bottom: 8px;
}

.subpage {
    min-height: 290px;
    margin-bottom : 10px;
}

.subpage-table {
    margin-bottom: 10px;
}

.table-toolbar {
    margin-bottom : 5px;

    .form-group {
        margin-left: auto;

        .form-control {
            width : 160px;
        }
    }

    .table-note {
        display: flex;
        align-items: center;
        font-size: 16px;

        .tbicon-large {
            margin: 0 3px;
        }
    }
}

.subpage-panel {
    margin: 0 auto;
    width: 750px;

    &.subpage-panel-sm {
        width: 500px;
    }
}

.bridge-info-table {
    white-space: nowrap;
    overflow: hidden;

    .bridge-info-name {
        font-weight: bold;
        padding: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .info-table-container {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 22px;
        }
    }
}

.info-table-container {
    table {
        .cell-title {
            font-weight : bold;
            font-size : 18px;
            color : @brand-primary;
        }

        td {
            padding : 3px;
        }

        .cell-data {
            font-weight : bold;
        }
    }

    &.info-table-container-padded {
        table td.cell-data {
            &:not(:last-child) {
                padding-right: 3rem;
            }
        }
    }
}

.login-info {
    .print-hide();

    text-align : right;
    font-size : 12px;
}

.operator-status {
    margin-left: auto;
    align-self: center;
}

.subpage.subpage-crudlist {
    .column-name {
        max-width : 300px;
        overflow : hidden;
        text-overflow : ellipsis;
    }

    .column-userID {
        max-width : 100px;
        overflow : hidden;
        text-overflow : ellipsis;
    }
}

.subpage.subpage-info {
    .note {
        padding : 3px;
        margin-top : 10px;
    }
}

body.view-ro {
    .rw-only {
        display: none !important;
    }
}

body:not(.view-ro) .ro-only {
    display: none !important;
}
