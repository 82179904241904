@webcall-button-height : 36px;

@import "functions/kvp";

@meter-bar-dim-opacity: 0.40;

.webCallButton(@name, @glyphon, @glyphoff:undefined) {
    .@{name} .tbicon:before {
        .kvp(@tbfontglyphmap, @glyphon);
        content : @kvp;
    }

    .test-args() when not (@glyphoff = undefined) {
        .@{name}.toggled .tbicon:before {
            .kvp(@tbfontglyphmap, @glyphoff);
            content : @kvp;
        }
    }

    .test-args();
}

.webCallButtonDisabled(@name, @glyphon, @glyphoff:undefined) {
    .@{name}[disabled] .tbicon:before {
        .kvp(@tbfontglyphmap, @glyphon);
        content : @kvp;
    }

    .test-args() when not (@glyphoff = undefined) {
        .@{name}.toggled[disabled] .tbicon:before {
            .kvp(@tbfontglyphmap, @glyphoff);
            content : @kvp;
        }
    }

    .test-args();
}

[class*="tbicon"] {
    font-size : 20px;
}

.btn.btn-primary.toggled {
    .btn-primary-toggled();
}

.webCallButton(btnHold,unhold,hold);
.webCallButton(btnMute,mic,mic-off);
.webCallButton(btnSettings,settings);
.webCallButton(btnOpenDialpad,dialpad);

// conf buttons
.webCallButton(btnHand,hand,hand-selected);
.webCallButton(btnLock,conf-unlock,conf-lock);
.webCallButton(btnChangeRole,host);
.webCallButton(btnEndConference,cross-circle);
.webCallButton(btnRecording,recording-off,recording);
.webCallButton(btnMusic,music-off,music);
.webCallButtonDisabled(btnMute,mute-locked-unmuted,mute-locked-muted);

.webCallButton(btnDisconnect,call-end);


// player
.webCallButton(btnTogglePause,hold);
.btnTogglePause.play .tbicon:before {
    .kvp(@tbfontglyphmap, play);
    content : @kvp;
}

.meters {
    display: flex;
    align-items: center;

    padding-left: 12px;
    margin-bottom: 4px;

    .meters-left {
        flex-grow: 1;
    }

    .btn-QualityMeterButton {
        margin-left: 4px;
        width: 34px;
    }
}

.input-meter-gauge {
    overflow: hidden;
    height: 6px;
    background-color: @progress-bg;
    border-radius: @progress-border-radius;

    .input-meter-gauge-level {
        height: 100%;
        font-size: @font-size-small;
        line-height: @progress-slider-height;
        color: @progress-bar-color;
        text-align: center;
        background-color: @progress-bar-bg;

        width: var(--inputLevel);
    }
}

.meter-control {
    display: flex;
    align-items: center;

    font-size: 16px;
    text-align: center;

    .tbicon {
        margin-right: 2px;
    }

    .meter-control-bar {
        flex-grow: 1;
    }
}

.volume-control {
    margin-bottom: 2px;
}

.mic-status-control {
    .mic-status-message {
        flex-grow: 1;
    }
}

.disconnected-menu {
    .btn-primary {
        width: 100%;
    }

    .btn-link {
        display: block;
        margin: 0 auto 5px auto;
    }
}

.message-container() {
    height: 22px;

    font-size: 20px;
    font-weight: bold;

    text-align: center;
}

.status-message {
    .message-container();
}

.timer {
    .message-container();
    margin: 1px 0;
}

.quality-warning-message {
    color: @alert-danger-border;
    font-weight: bold;
    font-size: 0;
    height: 0;
    transition: font-size 0.25s, height 0.25s;

    &.active {
        font-size: 20px;
        height: 20px;
    }
}

.connected {
    .btn-primary {
        width: 100%;
    }
}

.connecting {
    .btn-primary {
        width: 100%;
    }

    .status {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 20px;
        font-weight: bold;

        .dots {
            width: 20px;
        }
    }
}

.conf-call-ui .connecting .status {
    height: 238px;
}

.test-call-ui .connecting .status {
    height: 95px;
}

.ref-call-ui .connecting .status {
    height: 256px;
}

.player-call-ui .connecting .status {
    height: 88px;
}

&.webcall-compact {
    .connected {
        padding-top: 2px;
    }

    .connecting {
        .status {
            height: 40px;
        }
    }

    .btn-group {
        width: 100%;

        .btn {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

&.webcall-compact-player {
    .webCallButton(btnDisconnect, cross-circle);

    .btn-primary {
        width: auto;
    }

    .connecting {
        display: flex;
        button {
            margin-left: auto;
        }
    }

    .connected {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: 3px;
        }

        .status-message {
            flex: 0 0 60px;
            font-size: 16px;
            height: auto;
        }
    }
}

&.webcall-player {
    .playback-position {
        display: flex;
        justify-content: space-between;
    }

    .btn-toolbar {
        height: 30px;
        margin: 4px 0;

        .btnTogglePause {
            width: 50px;
            margin-right: 8px;
        }

        .input-meter-gauge {
            flex-grow: 1;
        }
    }
}

.btn-QualityMeterButton {
    span {
        opacity: 1;
    }

    .btn-success();

    &[data-quality-level="3"] {
        .tbicon-meter-bar-4 {
            opacity: @meter-bar-dim-opacity;
        }
    }

    &[data-quality-level="2"] {
        .btn-warning();

        .tbicon-meter-bar-3, .tbicon-meter-bar-4 {
            opacity: @meter-bar-dim-opacity;
        }
    }

    &[data-quality-level="1"] {
        .btn-danger();
        .tbicon-meter-bar-2, .tbicon-meter-bar-3, .tbicon-meter-bar-4 {
            opacity: @meter-bar-dim-opacity;
        }
    }

    &[data-quality-level="0"] {
        .btn-danger();
        span {
            opacity: @meter-bar-dim-opacity;
        }
    }
}

.btn-panel {
    .btn-toolbar-full {
        margin-bottom: 4px;
    }

    .btn {
        height : @webcall-button-height;

        .tbicon {
            display : block;
        }

        .digit {
            display : block;
            font-family : 'tbfont';
            margin-bottom : 4px;
        }

        .alpha {
            display : block;
            font-size : 10px;
        }
    }

    .sliding {
        // workaround to stop sliding dialpad button box-shadows overflow clipping
        margin: -10px;
        padding: 10px;
    }
}

.disable-backdrop {
    z-index : (@zindex-modal + 10);
}

.audioLoadState {
    font-weight : bold;
    margin-bottom : 5px;
    text-align: center;

    &[data-loading-state=unselected],
    &[data-loading-state=decodeFailure] {
        color: @state-danger-text;
    }

    &[data-loading-state=decodeSuccess] {
        color: @state-success-text;
    }
}

.quality-meter-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 4px;
}

.quality-meter-score {
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    .alert-success();

    &[data-quality-level="2"] {
        .alert-warning();
    }

    &[data-quality-level="1"], &[data-quality-level="0"] {
        .alert-danger();
    }
}

.quality-meter-table {
    text-align: center;

    > div {
        margin: 2px 0;
        padding: 2px;
    }

    .score-label {
        font-weight: bold;
    }

    .success {
        background-color: @state-success-bg;
    }

    .warning {
        background-color: @state-warning-bg;
    }

    .danger {
        background-color: @state-danger-bg;
    }
}

.modal-anchored {
    .modal-footer {
        .btn-toolbar-full();
    }

    .btn-toolbar-stacked {
        width: 100%;

        .btn {
            display: block;
            width: 100%;
        }

        .btn + .btn {
            margin-top: 15px;
        }
    }
}

&.always-listen-only {
    .status-message, .timer, .mic-status-control {
        display: none !important;
    }
}
