@print-header-overlay-height: 40px;

.paging-toolbar {
    display: flex;
    justify-content: space-between;

    .paging-toolbar-nav {
        margin-right: 10px;



        > .btn {
            font-family: tbfont;
            width: 30px;
        }

        > input,
        > div {
            text-align: center;
            width: 40px;
        }

        > div {
            padding: @padding-base-vertical @padding-base-horizontal;
            background-color: @input-group-addon-bg;
            border: 1px solid @input-group-addon-border-color;
        }
    }

    .paging-toolbar-resultCount {
        .btn-group {
            margin-left: 4px;
            > .btn {
                width: 60px;
            }
        }
    }
}

.print-header-overlay {
    .print-only();

    @media print {
        display : none !important;
    }

    z-index : @zindex-modal;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: @print-header-overlay-height;
    background-color: rgba(0, 0, 0, 0.3);

    .print-header-overlay-inner {
        display: flex;
        justify-content: space-between;
    }
}

.paging-control-print-footer {
    .print-only();

    text-align: right;
}

.subpage {
    .print-header {
        display: none;
        body.body-print & {
            display: flex;
        }

        margin-bottom: 10px;
    }

    body.body-print & {
        margin-top: @print-header-overlay-height;

        @media print {
            margin-top: 0;
        }
    }

    .subpage-header {
        display: flex;
        margin-bottom : 5px;

        .logo-container {
            .print-only();
        }
    }
}
