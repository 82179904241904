h1, h3, h5 {
    color : @brand-primary;
}

p {
    margin-bottom : 15px;
}

ul, ol {
    margin-bottom : 15px;
}

hr {
    border-top:1px solid #ccc;
    border-bottom:none;
    margin-top:20px;
}
