.set-table-row-height(@height) {
    tbody, tfoot {
        tr {
            height : @height;
        }
    }

    td.hover-cell {
        > button {
            height : @height;
        }
    }
}

th {
    text-align: left;
}

.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-spacing: 0;

  // workaround for hilariously old firefox bug
  // https://bugzilla.mozilla.org/show_bug.cgi?id=217769
  tbody:empty {
      display:none;
  }

  th,
  td {
      border : 1px solid var(--main-border);

      @media print {
          font-size : 12px;
      }
  }

  &.table-no-border {
      th, td {
          border: 0;
      }
  }

  th.shrink {
      width: 1px;
  }

  thead {
      th {
          font-weight: bold;
          padding : 5px 9px;
          color : @brand-primary;
          background-color : @panel-default-heading-bg;
          user-select: none;

          &.sorting, &.sorting_asc, &.sorting_desc {
              padding-right : 20px;
              .set-glyph(sort-unsorted);

              &:before {
                  font-family : tbfont;
                  float : right;
                  position : relative;
                  left : 14px;
                  color : @color-gray-light;
              }

              &:hover, &:focus, &:active {
                  outline : @color-btn-primary-focus-hover-border solid 2px;
              }
          }

          // active state
          &.sorting_asc, &.sorting_desc,
          &[data-sortorder=asc], &[data-sortorder=desc] {
              &:before {
                  color: @brand-primary;
              }
          }

          &.sorting_asc,
          &[data-sortorder=asc] {
              .set-glyph(sort-asc);
          }

          &.sorting_desc,
          &[data-sortorder=desc] {
              .set-glyph(sort-desc);
          }
      }

      .sorting,
      .sorting_asc,
      .sorting_desc,
      .sorting_asc_disabled,
      .sorting_desc_disabled {
          cursor: pointer;
      }
  }

  tbody, tfoot {
      th,
      td {
          padding: 6px;
      }
  }

  &.striped-old tbody {
      tr.even, tr.even + .striped-adjacent {
          background-color : @color-btn-primary-bg;
      }

      tr.odd, tr.odd + .striped-adjacent {
          background-color: #ffffff;
      }
  }

  &.striped {
      tbody, tfoot {
          tr:nth-child(even) {
              background-color: #ffffff;
          }

          tr:nth-child(odd) {
              background-color: @color-btn-primary-bg;
          }
      }

      tfoot.striped-inverse {
          tr:nth-child(even) {
              background-color: @color-btn-primary-bg;
          }

          tr:nth-child(odd) {
              background-color: #ffffff;
          }
      }
  }

  &.nowrap th, &.nowrap td {
      white-space: nowrap;
  }

  td.hover-cell {
      padding : 0 !important;

      > button, > .label-only {
          width: 100%;
          padding: 6px;

          overflow: hidden;
      }

      > button {
          border: 0;
          background-color : transparent;
          .box-shadow(none);

          &:not(:disabled) {
              &:hover, &:focus, &.open {
                  outline : @color-btn-primary-focus-hover-border solid 2px;
              }
          }
      }

      .btn-table-icon {
          font-size: 20px;
          font-family: tbfont;
          color: @brand-primary;
      }

      &.context-menu {
          > button {
              .content {
                  display: block;
                  overflow: hidden;
              }
          }
      }

      &.text-left {
          > button {
              text-align: left;
          }
      }
  }
}
