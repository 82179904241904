.subpage.subpage-settings {
    .bridge-settings-form {
        .gutter-8();
    }

    .button-mapper(~"button.btn-help", question-circle);
    .btn-help {
        vertical-align: middle;
        font-size: 16px;
        padding: 2px;
        margin-left: 2px;

        border: 1px solid transparent;
        border-radius: 4px;

        color: @brand-primary;

        &:hover,
        &:focus {
            text-decoration: none;
            border-color: @link-hover-color;
            color: @link-hover-color;
        }
    }

    .modal {
        font-size : 16px;
        line-height : 1.1;
    }

    .audio-control {
        height: 24px;

        .btn-play {
            font-family: tbfont;
            .set-glyph(play);
            &.playing {
                .set-glyph(stop);
            }
        }
    }
}
