.app-chat {
    .print-hide();

    border-right: 2px solid var(--portal-border);
    flex-grow: 0;
    flex-shrink: 0;
    width: var(--lcm-chat-width, 230px);
    padding: 4px;

    .app-chat-top-bar {
        display: flex;
        align-items: center;

        .btn-link {
            text-align: left;

            white-space: pre-wrap; // fallback for ie11
            white-space: break-spaces;
            word-wrap: anywhere;
        }

        // hackish overrides for enableAlert checkbox
        .app-chat-top-bar-alert {
            display: flex;
            align-items: center;
            margin-left: auto;

            .form-group {
                margin: 0 0 0 5px;

                label {
                    margin: 0;
                }
            }
        }
    }

    .app-chat-list-container {
        .form-control {
            min-height: 80px;
            height: auto;

            .app-chat-list {
                width: 100%;

                .app-chat-list-item {
                    padding: 2px;

                    &.app-chat-list-item-selected {
                        background-color: @color-btn-primary-bg;
                    }
                }
            }
        }
    }

    h4 {
        margin: 4px;
        height: 20px;
    }

    .app-chat-body {
        .app-chat-messages, textarea {
            margin-bottom: 4px;
        }

        .app-chat-messages {
            overflow-y: scroll;
            height: 380px;
            white-space: pre-wrap; // fallback for ie11
            white-space: break-spaces;
            word-wrap: anywhere;

            .message:not(:last-child) {
                margin-bottom: 8px;
            }

            .message-sender-participant {
                color: red;
            }

            .message-sender-operator {
                color: green;
            }
        }
    }
}
